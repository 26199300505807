export const MapPinIcon = (props) => (
  <svg
    width="36px"
    height="47.1px"
    viewBox="0 0 36 47.1"
    fill="none"
    {...props}
  >
    <path
      fill="#F5A720"
      d="M36,18c0-9.9-8.1-18-18-18C8.1,0,0,8.1,0,18v0.3c0,4.6,1.7,8.8,4.5,11.9L18,47.1l13.4-16.9
	c2.8-3.2,4.6-7.4,4.6-12V18z"
    />
    <path
      stroke="#FFFFFF"
      strokeWidth="2"
      d="M8,17.3c0,0,3.6-7.3,10-7.3s10,7.3,10,7.3s-3.6,7.3-10,7.3S8,17.3,8,17.3z"
    />
    <path
      stroke="#FFFFFF"
      strokeWidth="2"
      d="M18,20c1.5,0,2.7-1.2,2.7-2.7c0-1.5-1.2-2.7-2.7-2.7c-1.5,0-2.7,1.2-2.7,2.7C15.3,18.8,16.5,20,18,20z"
    />
  </svg>
)

// eslint-disable-next-line quotes
export const MapPinIconString = `<svg width="36px" height="47.1px" viewBox="0 0 36 47.1" fill="none"><path fill="#F5A720" d="M36,18c0-9.9-8.1-18-18-18C8.1,0,0,8.1,0,18v0.3c0,4.6,1.7,8.8,4.5,11.9L18,47.1l13.4-16.9 c2.8-3.2,4.6-7.4,4.6-12V18z"/><path stroke="#FFFFFF" stroke-width="2" d="M8,17.3c0 0,3.6-7.3,10-7.3s10,7.3,10,7.3s-3.6,7.3-10,7.3S8,17.3,8,17.3z"/><path stroke="#FFFFFF" stroke-width="2" d="M18,20c1.5,0,2.7-1.2,2.7-2.7c0-1.5-1.2-2.7-2.7-2.7c-1.5,0-2.7,1.2-2.7,2.7C15.3,18.8,16.5,20,18,20z"/></svg>`

export default MapPinIcon
