// import mapboxgl from "mapbox-gl"
import { useRef, useEffect, useState } from "react"

import { MapPinIconString } from "icons/MapPin"
// eslint-disable-next-line import/extensions
import simplifiedGeoJSON from "lib/bunubaGeoSimple.json"

// const mapStyle = "jayvd/cklu5rk7l0g0217qs63hp1v3n"
const mapStyle = "mapbox/outdoors-v12"

export default function useMapbox({
  center = [125.3253, -17.6432],
  zoom = 18,
  pin = false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onInit = (map, mapboxgl) => {},
  bunubaOverlay = false,
  ...mapOptions
}) {
  const ref = useRef(null)
  const [map, setMap] = useState(null)
  useEffect(() => {
    if (window?.requestIdleCallback) {
      requestIdleCallback(() => {
        import(/* webpackPrefetch: true */ "mapbox-gl")
      })
    } else {
      import(/* webpackPrefetch: true */ "mapbox-gl")
    }
  }, [])
  useEffect(() => {
    if (!ref?.current && map) {
      map.remove()
      setMap(null)
    }
    if (!ref?.current || map) {
      return () => {}
    }
    async function initMap() {
      const { default: mapboxgl } = await import("mapbox-gl")
      mapboxgl.accessToken = process.env.NEXT_PUBLIC_MAPBOX_TOKEN
      const tempMap = new mapboxgl.Map({
        container: ref.current,
        style: `mapbox://styles/${mapStyle}`,
        center,
        zoom,
        pitchWithRotate: false,
        scrollZoom: false,
        ...mapOptions,
      })
      setMap(tempMap)
      onInit(tempMap, mapboxgl)
      tempMap.addControl(new mapboxgl.NavigationControl())
      if (bunubaOverlay) {
        tempMap.on("load", async () => {
          const bunubaGeoJSON = await import("lib/bunubaGeo.json")
          tempMap.addSource("bunuba-overlay", {
            type: "geojson",
            data: bunubaGeoJSON,
          })
          tempMap.addSource("bunuba-country", {
            type: "geojson",
            data: {
              type: "Point",
              coordinates: [125.3253, -17.6432],
            },
          })
          tempMap.addLayer({
            id: "bunuba-country",
            source: "bunuba-country",
            type: "symbol",
            maxzoom: 10,
            minzoom: 6,
            paint: {
              "text-halo-width": 2,
              "text-halo-color": "#fff",
              "text-color": "#828282",
            },
            layout: {
              "text-field": "Bunuba Country",
              "text-transform": "uppercase",
              "text-letter-spacing": 1.5 / 14,
              "text-size": 14,
              "text-font": ["DIN Pro Bold", "Arial Unicode MS Regular"],
            },
          })
          tempMap.addLayer(
            {
              id: "bunuba-overlay",
              type: "line",
              source: "bunuba-overlay",
              paint: {
                "line-color": bunubaGeoJSON.properties.fill,
                "line-dasharray": [10, 3],
                "line-opacity": bunubaGeoJSON.properties["fill-opacity"],
              },
            },
            "waterway-label"
          )
        })
      }
      if (pin) {
        tempMap.on("load", () => {
          const pinElementTemplate = document.createElement("template")
          pinElementTemplate.innerHTML = MapPinIconString.trim()
          const pinElement = pinElementTemplate.content.firstChild
          new mapboxgl.Marker({
            element: pinElement,
            offset: [0, -23.5],
          })
            .setLngLat(center)
            .addTo(tempMap)
        })
      }
    }

    initMap()

    return () => {
      if (map) {
        map.remove()
        setMap(null)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, center, zoom, map])

  const marker2x =
    "https://bunuba-media-prod.s3-ap-southeast-2.amazonaws.com/bunuba-static-marker%402x.png"
  const marker =
    "https://bunuba-media-prod.s3-ap-southeast-2.amazonaws.com/bunuba-static-marker.png"

  return {
    ref,
    generateStaticMap: ({
      width = 1200,
      height = 643,
      retina = false,
      zoom: staticZoom = zoom,
      attribution = true,
      pin: staticPin = pin,
    }) =>
      `https://api.mapbox.com/styles/v1/${mapStyle}/static/${
        bunubaOverlay
          ? `geojson(${encodeURIComponent(JSON.stringify(simplifiedGeoJSON))})/`
          : ""
      }${
        staticPin
          ? `url-${encodeURIComponent(
              retina ? marker2x : marker
            )}(${center.join(",")})/`
          : ""
      }${[...center, staticZoom, 0, 0].join(",")}/${width}x${height}${
        retina ? "@2x" : ""
      }?access_token=${process.env.NEXT_PUBLIC_MAPBOX_TOKEN}${
        !attribution ? "&attribution=false" : ""
      }`,
  }
}
