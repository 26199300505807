import { useState, useEffect, createRef, useContext } from "react"
import Link from "next/link"
import tw from "twin.macro"

import useMuwayiTheme from "lib/useMuwayiTheme"
import useAuth from "lib/useAuth"

import { H5 } from "styles/Text"
import { gridBreakpoints } from "styles/Variables"
import TileDataContext from "context/TileDataContext"

import CaretDownIcon from "icons/CaretDown"
import icons from "icons"

import Image from "components/Image"
import SRText from "components/SRText"
import Container from "components/Container"
import PrimaryButton from "components/Buttons/Primary"
import SecondaryButton from "components/Buttons/Secondary"
import {
  NavigationWrapper,
  NavigationList,
  NavigationItemA,
  NavigationItemListItem,
  SubMenu,
  SubMenuList,
  SubMenuListItem,
  SubMenuOpener,
  SubMenuItemWrapper,
  SubMenuItemContent,
  SubMenuItemLabel,
  SubMenuA,
} from "./styles"

export interface NavigationItem {
  link: string
  label: string
  image?: {
    url: string
    alternativeText?: string
  }
  subNavigation?: NavigationItem[]
  user_groups?: {
    id: string | number
  }[]
}

function slugify(string) {
  return `${encodeURIComponent(string.replace(/\s/g, "-"))}`.toLowerCase()
}

export const Navigation = ({
  navigationItems,
  setIsNavOpen,
  isNavOpen,
  openMap,
  ...props
}: {
  navigationItems: NavigationItem[]
  setIsNavOpen: Function
  isNavOpen: boolean
  [propName: string]: any
}) => {
  const { CalloutMenu, CalloutButton } = useMuwayiTheme()
  const { canView } = useAuth()
  const { getGenericPage } = useContext(TileDataContext)
  const [openSubMenu, setOpenSubMenu] = useState(null)
  const [currentlyFocusable, setCurrentlyFocusable] = useState(0)
  const [currentlySubFocusable, setCurrentlySubFocusable] = useState(-1)
  const [shouldFocusSubmenu, setShouldFocusSubmenu] = useState(false)
  const [isMobileMenu, setIsMobileMenu] = useState(false)
  const [images, setImages] = useState({})
  // https://stackoverflow.com/questions/54633690/how-can-i-use-multiple-refs-for-an-array-of-elements-with-hooks
  const [navLinks, setNavLinks] = useState(
    navigationItems.map(() => createRef<HTMLAnchorElement>())
  )
  const [subNavLinks, setSubNavLinks] = useState(
    navigationItems.reduce(
      (accumulator, navItem) => [
        ...accumulator,
        navItem?.subNavigation?.map(() => createRef<HTMLAnchorElement>()) || [],
      ],
      []
    )
  )

  useEffect(() => {
    const links = navigationItems.reduce(
      (accumulator, navItem) => [
        ...accumulator,
        ...(navItem?.subNavigation
          .filter(({ image }) => !image)
          .map(({ link }) => link) || []),
      ],
      []
    )

    Promise.all(
      links.map((link) =>
        getGenericPage(link.slice(1))
          .then(({ Hero }) => {
            return [link, Hero?.BackgroundImage]
          })
          .catch((e) => {
            console.log(`No image for ${link} in submenu`, e)
            return ["", null]
          })
      )
    ).then((pages) => {
      setImages(pages.reduce((a, [k, v]) => ({ ...a, [k]: v }), {}))
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigationItems])

  // Mark whether the screen is mobile-sized
  useEffect(() => {
    let awaitingIdle = false
    const onResize = () => {
      awaitingIdle = false
      if (window.innerWidth < gridBreakpoints.lg) {
        setIsMobileMenu(true)
      } else {
        setIsMobileMenu(false)
      }
    }
    onResize()
    const resizeCaller = () => {
      if (awaitingIdle) {
        return
      }
      awaitingIdle = true
      if (window?.requestIdleCallback) {
        requestIdleCallback(onResize)
      } else {
        setTimeout(onResize, 60)
      }
    }
    window.addEventListener("resize", resizeCaller)
    return () => {
      window.removeEventListener("resize", resizeCaller)
    }
  }, [])

  // Update ref scaffolding on nav update
  useEffect(() => {
    setNavLinks(navigationItems.map(() => createRef<HTMLAnchorElement>()))
    setSubNavLinks(
      navigationItems.reduce(
        (accumulator, navItem) => [
          ...accumulator,
          navItem?.subNavigation.map(() => createRef<HTMLAnchorElement>()) ||
            [],
        ],
        []
      )
    )
  }, [navigationItems])

  // Triggers focus on submenu when it's opened (can't happen immediately as hidden elements can't be focused)
  useEffect(() => {
    if (shouldFocusSubmenu && openSubMenu) {
      requestAnimationFrame(() => {
        subNavLinks[currentlyFocusable][0].current.focus()
        setShouldFocusSubmenu(false)
      })
    }
  }, [currentlyFocusable, openSubMenu, shouldFocusSubmenu, subNavLinks])

  // Update parent nav open state
  useEffect(() => {
    if (isMobileMenu) {
      return
    }
    if (openSubMenu) {
      setIsNavOpen(true)
    } else {
      setIsNavOpen(false)
    }
  }, [openSubMenu, setIsNavOpen, isMobileMenu])

  // Accessibility key handling
  // Arrow left = move left, Arrow right = move right, Arrow down = open relevant submenu
  const navigationKeyDown = (e) => {
    switch (e.key) {
      case "ArrowLeft":
        if (currentlyFocusable > 0) {
          e.preventDefault()
          e.stopPropagation()
          navLinks[currentlyFocusable - 1].current.focus()
          setCurrentlyFocusable(currentlyFocusable - 1)
        }
        break
      case "ArrowRight":
        if (currentlyFocusable < navigationItems.length - 1) {
          e.preventDefault()
          e.stopPropagation()
          navLinks[currentlyFocusable + 1].current.focus()
          setCurrentlyFocusable(currentlyFocusable + 1)
        }
        break
      case "ArrowDown":
        if (
          navigationItems[currentlyFocusable]?.subNavigation &&
          navigationItems[currentlyFocusable]?.subNavigation.length > 0
        ) {
          e.preventDefault()
          e.stopPropagation()
          setOpenSubMenu(`${e.target.id}-submenu`)
          setCurrentlySubFocusable(0)
          setShouldFocusSubmenu(true)
        }
        break
      default:
        break
    }
  }

  // Accessibility for submenus
  // Arrow left or up = move left / return to parent, Arrow right or down = move right
  const subNavigationKeyDown = (e) => {
    switch (e.key) {
      case "ArrowLeft":
      case "ArrowUp":
        e.preventDefault()
        e.stopPropagation()
        if (currentlySubFocusable === 0) {
          navLinks[currentlyFocusable].current.focus()
          setOpenSubMenu(null)
          setCurrentlySubFocusable(-1)
        } else {
          subNavLinks[currentlyFocusable][
            currentlySubFocusable - 1
          ].current.focus()
          setCurrentlySubFocusable(currentlySubFocusable - 1)
        }
        break
      case "ArrowRight":
      case "ArrowDown":
        if (
          currentlySubFocusable <
          navigationItems[currentlyFocusable]?.subNavigation.length - 1
        ) {
          e.preventDefault()
          e.stopPropagation()
          subNavLinks[currentlyFocusable][
            currentlySubFocusable + 1
          ].current.focus()
          setCurrentlySubFocusable(currentlySubFocusable + 1)
        }
        break
      default:
        break
    }
  }

  const onHover = (submenuId) => {
    if (isMobileMenu) {
      return
    }
    setOpenSubMenu(submenuId)
  }

  const onMouseLeave = (submenuId) => {
    if (isMobileMenu) {
      return
    }
    if (openSubMenu === submenuId && currentlySubFocusable === -1) {
      setOpenSubMenu(null)
    }
  }

  return (
    <NavigationWrapper open={isNavOpen} {...props}>
      <NavigationList>
        {navigationItems.map(({ user_groups, ...item }, idx) => {
          if (!canView(user_groups) || !item.link) {
            return null
          }
          const submenuId = slugify(`${item.label}-submenu`)
          return (
            <NavigationItemListItem
              key={Object.values(item).join("-")}
              onMouseOver={() => {
                onHover(submenuId)
              }}
              onMouseLeave={() => {
                onMouseLeave(submenuId)
              }}
              active={openSubMenu === submenuId}
            >
              <Link passHref href={item.link}>
                <NavigationItemA<"a">
                  tabIndex={currentlyFocusable === idx ? 0 : -1}
                  onKeyDown={navigationKeyDown}
                  id={slugify(item.label)}
                  ref={navLinks[idx]}
                  active={openSubMenu === submenuId}
                  target={item.link.startsWith("http") ? "_blank" : "_self"}
                >
                  {item.label}
                  {item?.subNavigation && item?.subNavigation.length > 0 && (
                    <SubMenuOpener
                      aria-controls={submenuId}
                      aria-haspopup="true"
                      aria-expanded={
                        openSubMenu === submenuId ? "true" : "false"
                      }
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        if (openSubMenu === submenuId) {
                          setOpenSubMenu(null)
                        } else {
                          setOpenSubMenu(submenuId)
                        }
                      }}
                      css={
                        openSubMenu === submenuId &&
                        tw`transform rotate-180 lg:rotate-0`
                      }
                    >
                      <SRText>Open sub-menu</SRText>
                      <CaretDownIcon tw="w-4 h-3 lg:(w-3 h-2)" />
                    </SubMenuOpener>
                  )}
                </NavigationItemA>
              </Link>

              {item?.subNavigation && item?.subNavigation.length > 0 && (
                <>
                  <SubMenu hidden={openSubMenu !== submenuId} id={submenuId}>
                    <Container>
                      <SubMenuList>
                        {item?.subNavigation.map(
                          (
                            { user_groups: child_user_groups, ...child },
                            childIdx
                          ) =>
                            canView(child_user_groups) && (
                              <SubMenuListItem
                                key={Object.values(child).join("-")}
                              >
                                <Link passHref href={child.link}>
                                  <SubMenuA
                                    ref={subNavLinks?.[idx]?.[childIdx]}
                                    tabIndex={
                                      currentlySubFocusable === childIdx
                                        ? 0
                                        : -1
                                    }
                                    onKeyDown={subNavigationKeyDown}
                                    role="link"
                                    target={
                                      child.link.startsWith("http")
                                        ? "_blank"
                                        : "_self"
                                    }
                                  >
                                    <SubMenuItemWrapper>
                                      <SubMenuItemContent>
                                        <SubMenuItemLabel>
                                          {child.label}
                                        </SubMenuItemLabel>
                                      </SubMenuItemContent>
                                      {(child?.image ||
                                        images?.[child.link]) && (
                                        <div tw="hidden lg:block">
                                          <Image
                                            src={
                                              child?.image?.url ||
                                              images?.[child.link]?.url
                                            }
                                            alt={
                                              child?.image?.alternativeText ||
                                              images?.[child.link]
                                                ?.alternativeText
                                            }
                                            aria-hidden="true"
                                            layout="fill"
                                            sizes="210px"
                                            objectFit="cover"
                                          />
                                        </div>
                                      )}
                                    </SubMenuItemWrapper>
                                  </SubMenuA>
                                </Link>
                              </SubMenuListItem>
                            )
                        )}
                      </SubMenuList>
                    </Container>
                  </SubMenu>
                </>
              )}
            </NavigationItemListItem>
          )
        })}
      </NavigationList>
      <ul tw="flex flex-wrap justify-center items-center lg:hidden list-none">
        {CalloutMenu?.map(({ icon, link, label, id }) => {
          if (!link || !label) {
            return null
          }
          const Icon = icons[icon] || icons.info
          return (
            <li key={id} tw="mx-4">
              <Link href={link} passHref>
                <H5
                  as={link === "#map" ? "button" : "a"}
                  onClick={() => {
                    if (link !== "#map") {
                      return
                    }
                    openMap()
                  }}
                  tw="flex items-center"
                >
                  {icon && (
                    <div tw="mr-2">
                      <Icon />
                    </div>
                  )}
                  {label}
                </H5>
              </Link>
            </li>
          )
        })}
        {CalloutButton && (
          <li tw="mx-4 md:mt-0">
            <Link passHref href={CalloutButton.link}>
              {CalloutButton.theme === "secondary" ? (
                <SecondaryButton target="_blank" as="a" rel="noopener">
                  {CalloutButton.label}
                </SecondaryButton>
              ) : (
                <PrimaryButton target="_blank" as="a" rel="noopener">
                  {CalloutButton.label}
                </PrimaryButton>
              )}
            </Link>
          </li>
        )}
      </ul>
    </NavigationWrapper>
  )
}

export default Navigation
