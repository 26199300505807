import { useState } from "react"
import tw from "twin.macro"
import Head from "next/head"
import styled from "styled-components"

import useMapbox from "lib/useMap"
import API from "lib/api"

import Modal from "components/Modal"
import CloseIcon from "icons/Close"
import { breakpoint } from "styles/Variables"

import { MapPinIconString } from "icons/MapPin"
import { popupOffsets, generatePopupHTML } from "components/dynamic/map/popup"
import { MapPopupStyles } from "components/dynamic/map/styles"

const MapContainer = styled.div<{ loaded: boolean; backgroundImage: string }>`
  position: absolute;
  background-image: url("${({ backgroundImage }) => backgroundImage}");
  background-size: cover;
  background-position: center;
  overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  ${breakpoint.md`
    top: 6px;
    left: 6px;
    bottom: 6px;
    right: 6px;
  `}
  .mapboxgl-popup-content {
    ${MapPopupStyles}
  }
  .mapboxgl-canvas-container,
  .mapboxgl-control-container {
    opacity: ${({ loaded }) => (loaded ? 1 : 0)};
    transition: opacity 0.5s;
  }
  .mapboxgl-ctrl-top-right .mapboxgl-ctrl-group {
    margin-top: 110px;
    margin-right: 54px;
    ${breakpoint.md`
    
      margin-right: 48px;
    `}
  }
`

const MapModalBox = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  box-shadow: 0 40px 40px rgba(0, 0, 0, 0.2);
  ${breakpoint.md`
    position: relative;
    width: 1212px;
    height: 655px;
  `}
`

export const MapModal = ({
  lng = 125.3253,
  lat = -17.6432,
  zoom = 8,
  setModalOpen,
  modalOpen,
  bunubaOverlay = true,
  pin = false,
  allExperiences = false,
  ...props
}) => {
  const [isMapLoaded, setMapLoaded] = useState(false)
  const { ref: mapContainer, generateStaticMap } = useMapbox({
    center: [lng, lat],
    zoom,
    bunubaOverlay,
    pin,
    async onInit(map, mapbox) {
      map.scrollZoom.enable()

      map.on("load", () => {
        setMapLoaded(true)
      })
      map.on("remove", () => {
        setMapLoaded(false)
      })
      if (allExperiences) {
        const { data } = await API.gql(`
          {
            experiencePages {
              Name
              Hero {
                Latitude
                Longitude
                BackgroundImage {
                  url
                }
              }
            }
          }
        `)

        data.data.experiencePages.forEach((Pin) => {
          if (!(Pin?.Hero?.Longitude && Pin?.Hero?.Latitude)) {
            return
          }
          const pinElementTemplate = document.createElement("template")
          pinElementTemplate.innerHTML = MapPinIconString.trim()
          const pinElement = pinElementTemplate.content.firstChild
          const popup = new mapbox.Popup({
            maxWidth: "90%",
            offset: popupOffsets,
            closeButton: false,
          }).setHTML(generatePopupHTML(Pin))
          new mapbox.Marker({
            element: pinElement,
            offset: [0, -23.5],
          })
            .setLngLat([Pin.Hero.Longitude, Pin.Hero.Latitude])
            .setPopup(popup)
            .addTo(map)
        })
      }
    },
  })

  return (
    <>
      <Head>
        <link
          href="https://api.tiles.mapbox.com/mapbox-gl-js/v2.1.1/mapbox-gl.css"
          rel="stylesheet"
          key="mapbox-css"
        />
      </Head>
      {modalOpen && (
        <Modal
          isOpen={modalOpen}
          close={() => setModalOpen(false)}
          label="Map popup"
          {...props}
          removeBox
        >
          <MapModalBox>
            <button
              type="button"
              onClick={() => setModalOpen(false)}
              tw="absolute top-10 right-10 z-10 p-5 text-black"
            >
              <CloseIcon tw="block" />
            </button>
            <MapContainer
              ref={mapContainer}
              loaded={isMapLoaded}
              backgroundImage={generateStaticMap({})}
            />
          </MapModalBox>
        </Modal>
      )}
    </>
  )
}

export default MapModal
