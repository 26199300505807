import dynamic from "next/dynamic"

export const icons = {
  alert: dynamic(import("icons/Alert")),
  arrowDown: dynamic(import("icons/ArrowDown")),
  arrowLeft: dynamic(import("icons/ArrowLeft")),
  arrowRight: dynamic(import("icons/ArrowRight")),
  atmosphere: dynamic(import("icons/Atmosphere")),
  audio: dynamic(import("icons/Audio")),
  bin: dynamic(import("icons/Bin")),
  camera: dynamic(import("icons/Camera")),
  confirmed: dynamic(import("icons/Confirmed")),
  copy: dynamic(import("icons/Copy")),
  download: dynamic(import("icons/Download")),
  envelope: dynamic(import("icons/Envelope")),
  facebook: dynamic(import("icons/Facebook")),
  heart: dynamic(import("icons/Heart")),
  hidden: dynamic(import("icons/Hidden")),
  info: dynamic(import("icons/Info")),
  instagram: dynamic(import("icons/Instagram")),
  linkedin: dynamic(import("icons/LinkedIn")),
  location: dynamic(import("icons/Location")),
  map: dynamic(import("icons/Map")),
  mapPin: dynamic(import("icons/MapPin")),
  phone: dynamic(import("icons/Phone")),
  play: dynamic(import("icons/Play")),
  popOut: dynamic(import("icons/PopOut")),
  print: dynamic(import("icons/Print")),
  share: dynamic(import("icons/Share")),
  twitter: dynamic(import("icons/Twitter")),
}

export default icons
