import styled, { css } from "styled-components"

import { fontBase, colors, breakpoint } from "styles/Variables"

export const MapPopupStyles = css`
  padding: 0;
  overflow: hidden;
  width: 325px;
  border-radius: 10px;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.15);
  font-family: sofia-pro, sans-serif;
  h2 {
    margin: 0;
    line-height: 1.2;
  }
  h3 {
    margin: 5px 0 0 0;
    font-size: ${14 / fontBase} rem;
    font-weight: 400;
    color: ${colors["dark-grey"]};
  }
`

export const FullMapContainer = styled.div`
  width 100%;
  background-color:white;
  height: 544px;
  position: relative;
  overflow: hidden;
  background-size: cover;
  .mapboxgl-popup-content {
    ${MapPopupStyles}
  }
`

export const MapAContainer = styled(FullMapContainer)`
  width: calc(100% + 40px);
  height: 462px;
  margin: 40px -20px;
  ${breakpoint.lg`
    width: 100%;
    border-radius: 10px;
    margin: 60px 0;
  `}
`

export const FadeOverlay = styled.div`
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  height: 322px;
  background-image: linear-gradient(transparent, white);
  pointer-events: none;
  width: 100%;
`

export const MapOverlayImageContainer = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 2;
  width: 89px;
  ${breakpoint.lg`
    width: 151px;
  `}
`
