import { useState, useRef, useEffect } from "react"
import Link from "next/link"
import { useRouter } from "next/router"
import tw from "twin.macro"

import useMuwayiTheme from "lib/useMuwayiTheme"
import useAuth from "lib/useAuth"

import Grid from "components/Grid"
import Column from "components/Column"
import MapModal from "components/MapModal"
import SRText from "components/SRText"
import PrimaryButton from "components/Buttons/Primary"
import SecondaryButton from "components/Buttons/Secondary"
import Image from "components/Image"

import icons from "icons"
import BunubaIcon from "icons/Bunuba"
import HamburgerIcon from "icons/Hamburger"
import CloseIcon from "icons/Close"

import Navigation, { NavigationItem } from "./Navigation"
import {
  HeaderEl,
  NavigationItemListItem,
  SecondaryNavigationItemA,
  MobileOpener,
} from "./styles"

const navigationItems: NavigationItem[] = [
  {
    label: "Things to do",
    link: "/things-to-do",
    subNavigation: [
      {
        label: "Bunuba Country",
        link: "/bunuba-country",
      },
      {
        label: "Locations",
        link: "/locations",
      },
      {
        label: "Experiences",
        link: "/experiences",
      },
      {
        label: "Events",
        link: "/events",
      },
    ],
  },
  {
    label: "Plan your trip",
    link: "/plan-your-trip",
    subNavigation: [
      {
        label: "Itineraries",
        link: "/itineraries",
      },
      {
        label: "Staying on Country",
        link: "/staying-on-country",
      },
      {
        label: "Maps and Guides",
        link: "/maps-and-guides",
      },
      {
        label: "Safety Information",
        link: "/safety-information",
      },
    ],
  },
  {
    label: "Culture",
    link: "/culture",
    subNavigation: [
      {
        label: "Language & Culture",
        link: "/language-and-culture",
      },
      {
        label: "Ngarranggani (dreaming) stories",
        link: "/stories",
      },
      {
        label: "History",
        link: "/history",
      },
      {
        label: "Caring for Country",
        link: "/caring-for-country",
      },
      {
        label: "Art",
        link: "/art",
      },
    ],
  },
]

export const Header = ({ notFound }) => {
  const router = useRouter()
  const {
    PrimaryMenu,
    CalloutMenu,
    CalloutButton,
    logo,
    title,
    showLogin,
  } = useMuwayiTheme()
  const { user } = useAuth()
  const isErrorPage = notFound
  const [isNavOpen, setIsNavOpen] = useState(false)
  const [isMapOpen, setIsMapOpen] = useState(false)
  const [isDocumentScrolled, setIsDocumentScrolled] = useState(false)
  const headerTrigger = useRef(null)

  // Set header to solid after scrolling down the page
  // Change the position of the change by altering the height of the trigger element
  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setIsDocumentScrolled(false)
      } else {
        setIsDocumentScrolled(true)
      }
    })
    observer.observe(headerTrigger.current)
    return () => {
      observer.disconnect()
    }
  }, [])

  // Close the nav when you click a link
  useEffect(() => {
    const closeNav = () => {
      setIsNavOpen(false)
    }
    router.events.on("routeChangeStart", closeNav)
    return () => {
      router.events.off("routeChangeStart", closeNav)
    }
  }, [router.events])

  return (
    <>
      <HeaderEl
        className="group"
        open={isNavOpen || isDocumentScrolled || isErrorPage}
      >
        <Grid valign="center" tw="flex-grow">
          <Column
            as="nav"
            size={{ xs: 4 / 12, lg: 5 / 12, xl: 4 / 12 }}
            tw="print:hidden"
          >
            <MobileOpener
              onClick={() => setIsNavOpen(!isNavOpen)}
              aria-haspopup="true"
              aria-controls="header-nav"
              aria-expanded={isNavOpen ? "true" : "false"}
              css={isNavOpen && tw`hidden!`}
              tw="-ml-3"
            >
              <HamburgerIcon aria-hidden="true" />
              <SRText>Open Navigation Menu</SRText>
            </MobileOpener>
            <Navigation
              id="header-nav"
              navigationItems={
                (PrimaryMenu?.length > 0 && PrimaryMenu) || navigationItems
              }
              setIsNavOpen={setIsNavOpen}
              isNavOpen={isNavOpen}
              openMap={() => {
                setIsNavOpen(false)
                setIsMapOpen(true)
              }}
            />
          </Column>
          <Column
            size={{ xs: 4 / 12, lg: 2 / 12, xl: 4 / 12 }}
            expandPrint
            css={
              isNavOpen || isDocumentScrolled || isErrorPage
                ? tw`text-center text-primary`
                : tw`text-center`
            }
            tw="transition-colors duration-300 print:text-right print:text-primary"
          >
            <Link href="/" passHref>
              <a>
                {logo && (
                  <div
                    tw="h-10 w-24 max-w-full lg:(h-14 w-30) relative mx-auto brightness-[20] group-hover:brightness-100"
                    css={
                      (isNavOpen || isDocumentScrolled || isErrorPage) &&
                      tw`brightness-100`
                    }
                  >
                    <Image
                      src={logo.url}
                      alt={title}
                      unoptimized
                      priority
                      layout="fill"
                      objectFit="contain"
                    />
                  </div>
                )}
                {!logo && (
                  <BunubaIcon tw="h-10 w-24 max-w-full lg:(h-auto w-auto mt-3)" />
                )}
              </a>
            </Link>
          </Column>
          <Column
            size={{ xs: 4 / 12, lg: 5 / 12, xl: 4 / 12 }}
            tw="text-right print:hidden"
          >
            <MobileOpener
              aria-label="Close menu"
              onClick={() => setIsNavOpen(false)}
              css={isNavOpen ? tw`lg:hidden!` : tw`hidden!`}
              tw="ml-auto -mr-3"
              aria-haspopup="true"
              aria-controls="header-nav"
              aria-expanded={isNavOpen ? "true" : "false"}
            >
              <CloseIcon />
            </MobileOpener>
            <ul
              tw="justify-end items-center list-none p-0 -m-3"
              css={isNavOpen ? tw`hidden lg:flex` : tw`flex`}
            >
              {CalloutMenu?.map(({ icon, link, label, id }) => {
                const Icon = icons[icon] || icons.info
                const CalloutBody = (
                  <>
                    {icon && (
                      <div tw="md:mr-2">
                        <Icon />
                      </div>
                    )}
                    <span tw="sr-only lg:not-sr-only">{label}</span>
                  </>
                )
                return link === "#map" ? (
                  <NavigationItemListItem key={id}>
                    <SecondaryNavigationItemA
                      as="button"
                      type="button"
                      onClick={() => setIsMapOpen(true)}
                      aria-haspopup="true"
                      aria-expanded={isMapOpen ? "true" : "false"}
                      aria-controls="header-map"
                      tw="transition-colors duration-300 cursor-pointer"
                    >
                      {CalloutBody}
                    </SecondaryNavigationItemA>
                  </NavigationItemListItem>
                ) : (
                  <NavigationItemListItem key={id}>
                    <Link passHref href={link}>
                      <SecondaryNavigationItemA>
                        {CalloutBody}
                      </SecondaryNavigationItemA>
                    </Link>
                  </NavigationItemListItem>
                )
              })}
              {showLogin && !user && (
                <NavigationItemListItem>
                  <Link href="/login" passHref>
                    <SecondaryNavigationItemA>Login</SecondaryNavigationItemA>
                  </Link>
                </NavigationItemListItem>
              )}
              {showLogin && user && (
                <NavigationItemListItem>
                  <Link href="/account" passHref>
                    <SecondaryNavigationItemA>Account</SecondaryNavigationItemA>
                  </Link>
                </NavigationItemListItem>
              )}
              {CalloutButton && (
                <NavigationItemListItem tw="hidden lg:block">
                  <Link passHref href={CalloutButton.link}>
                    {CalloutButton.theme === "secondary" ? (
                      <SecondaryButton target="_blank" as="a" rel="noopener">
                        {CalloutButton.label}
                      </SecondaryButton>
                    ) : (
                      <PrimaryButton target="_blank" as="a" rel="noopener">
                        {CalloutButton.label}
                      </PrimaryButton>
                    )}
                  </Link>
                </NavigationItemListItem>
              )}
            </ul>
          </Column>
        </Grid>
      </HeaderEl>
      {isMapOpen && (
        <MapModal
          id="header-map"
          setModalOpen={setIsMapOpen}
          modalOpen
          allExperiences
        />
      )}
      <div
        tw="invisible absolute top-0 z-10 w-full h-50"
        aria-hidden="true"
        id="header-scroll-trigger"
        ref={headerTrigger}
      />
    </>
  )
}

export default Header
