import ReactDOMServer from "react-dom/server"

import { lambdaLoader } from "components/Image"
import Language from "components/Language"

export const markerHeight = 50
const markerRadius = 23
const linearOffset = 25
export const popupOffsets = {
  top: [0, 0],
  "top-left": [0, 0],
  "top-right": [0, 0],
  bottom: [0, -markerHeight],
  "bottom-left": [
    linearOffset,
    (markerHeight - markerRadius + linearOffset) * -1,
  ],
  "bottom-right": [
    -linearOffset,
    (markerHeight - markerRadius + linearOffset) * -1,
  ],
  left: [markerRadius, (markerHeight - markerRadius) * -1],
  right: [-markerRadius, (markerHeight - markerRadius) * -1],
}

const Popup = ({ Hero, region, Name }) => (
  <div style={{ display: "flex" }}>
    {Hero?.BackgroundImage?.url && (
      <img
        alt=""
        src={lambdaLoader({
          src: Hero.BackgroundImage.url,
          width: 210,
          quality: 75,
        })}
        width="94"
        style={{ objectFit: "cover", minHeight: "70px" }}
      />
    )}
    <div style={{ padding: "16px 14px" }}>
      <h2>
        <Language unwrap>{region?.Name || Name}</Language>
      </h2>
      {region && (
        <h3>
          <Language unwrap>{Name}</Language>
        </h3>
      )}
    </div>
  </div>
)

export function generatePopupHTML(Pin) {
  return ReactDOMServer.renderToString(Popup(Pin))
}
