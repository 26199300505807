import styled from "styled-components"

import { colors } from "styles/Variables"
import { H5 } from "styles/Text"

export const SecondaryButton = styled(H5).attrs(({ as }: { as?: string }) => ({
  as: as || "a",
}))<{
  dark?: boolean
}>`
  background-color: transparent;
  color: ${({ dark }) => (dark ? colors["dark-grey"] : colors.white)};
  border: 1px solid currentColor;
  padding: 15px 21px;
  border-radius: 60px;
  display: inline-block;
  transition: background-color 0.3s;
  cursor: pointer;
  text-align: center;
  &:hover,
  &:focus {
    background-color: ${({ dark }) =>
      dark ? colors["light-grey"] : "rgba(255,255,255, 0.2)"};
  }
  &:disabled {
    background-color: ${colors["light-grey"]};
    color: ${colors["med-grey"]};
  }
`

export default SecondaryButton
