export const CaretDownIcon = (props) => (
  <svg
    width="12"
    height="6"
    viewBox="0 0 12 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6 6L0.803849 -9.78799e-07L11.1962 -7.02746e-08L6 6Z"
      fill="currentColor"
    />
  </svg>
)

export default CaretDownIcon
