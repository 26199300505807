import styled, { css } from "styled-components"

import {
  colors,
  breakpoint,
  breakpoints,
  gridBreakpoints,
  fontBase,
} from "styles/Variables"
import { H5 } from "styles/Text"

const mobileHeaderHeight = 90
const headerHeight = 112

const headerActive = css`
  @media screen {
    background-color: ${colors.white};
    color: ${colors.black};
    border-bottom: 1px solid #ededed;
  }
`

export const MobileOpener = styled.button.attrs((props) => ({
  type: "button",
  "aria-label": props["aria-label"] || "Open menu",
}))`
  background-color: ${colors.white};
  color: ${colors.black};
  height: 40px;
  width: 40px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${breakpoint.lg`
    display: none;
  `}
`

export const HeaderEl = styled.header<{
  open: boolean
}>`
  position: fixed;
  z-index: 200;
  top: 0;
  left: 0;
  width: 100%;
  height: ${mobileHeaderHeight}px;
  padding: 0 20px;
  display: flex;
  align-items: stretch;
  transition: background-color 0.3s, border 0.3s;
  border-bottom: 1px solid transparent;
  ${breakpoint.lg`
    height: 112px;
    padding: 0 40px;
  `}
  // string concat inside functions/mixins doesn't work
  @media (min-width: ${breakpoints.lg}) {
    &:hover {
      ${headerActive}
      .bunuba-country-logo {
        color: ${colors.primary};
      }
    }
  }
  ${({ open }) =>
    open
      ? headerActive
      : css`
          color: ${colors.white};
        `}
  @media print {
    padding: 0;
    height: auto;
  }
`

export const NavigationWrapper = styled.div<{
  open: boolean
}>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  left: 0;
  top: 90px;
  width: 100%;
  height: calc(100% - 90px);
  padding: 55px 0 35px;
  background-color: ${colors.white};
  color: ${colors.black};
  overflow: auto;
  transition: transform 0.3s ease-out;
  transform: translateX(${({ open }) => (open ? "0" : "-100%")});
  ${breakpoint.lg`
    transform: none;
    display: flex;
    position: static;
    flex-direction: row;
    justify-content: flex-start;
    background-color: transparent;
    color: inherit;
    padding: 0;
    margin: 0 -13px 0 -12px;
    overflow: visible;
  `}
`

export const NavigationList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  ${breakpoint.lg`
    display: flex;
    flex-direction: row;
  `}
`

export const SecondaryNavigationItemA = styled(H5).attrs(
  ({ as }: { as?: string }) => ({
    as: as || "a",
  })
)<{
  active?: boolean
}>`
  display: flex;
  align-items: center;
  // margin-top: -13px;
  height: ${headerHeight}px;
  // padding-top: 16px;
  transition: border 0.3s, color 0.3s;
  text-align: left;
  visibility: invisible;
  border-bottom: 3px solid
    ${({ active }) => (active ? colors.primary : "transparent")};
  &:hover {
    color: ${colors.primary};
  }
`

export const NavigationItemA = styled(SecondaryNavigationItemA).attrs(() => ({
  as: "a",
}))<{
  active?: boolean
}>`
  @media (max-width: ${gridBreakpoints.lg - 1}px) {
    font-weight: 700;
    font-size: ${36 / fontBase}rem;
    letter-spacing -${1 / 36}em;
    justify-content: space-between;
    padding: 18px 1rem 17px;
    border-bottom: none;
  }
`

export const NavigationItemListItem = styled.li<{
  active?: boolean
}>`
  margin-left: 12px;
  margin-right: 13px;
  ${({ active }) =>
    active &&
    css`
      color: ${colors.primary};
    `}
`

export const SubMenuOpener = styled.button.attrs(() => ({
  type: "button",
  tabIndex: -1,
}))`
  padding: 15px;
  margin-right: -15px;
  cursor: pointer;
  ${breakpoint.lg`
    padding: 6px;
    margin-right: -6px;
  
  `}
  svg {
    display: block;
  }
`

export const SubMenu = styled.div`
  transition: visibility 0.3s, opacity 0.3s;
  margin-top: -20px;
  ${breakpoint.lg`
    margin-top: 0;
    position: absolute;
    top: 112px;
    left: 0;
    width: 100%;
    padding: 40px 0 57px;
    background-color: white;
    box-shadow: 0 40px 40px rgba(0, 0, 0, 0.2);
    &[hidden] {
      visibility: hidden;
      display: block;
      pointer-events: none;
      opacity: 0;
    }
  `}
`

export const SubMenuList = styled.ul`
  list-style: none;
  padding: 0;
  ${breakpoint.lg`
    margin: -10px;
    display: flex;
    flex-wrap: wrap;
  `}
`

export const SubMenuListItem = styled.li`
  ${breakpoint.lg`
    padding: 10px 10px;
    width: 20%;
  `}
  a {
    text-decoration: none;
  }
`

export const SubMenuItemWrapper = styled.div`
  ${breakpoint.lg`
    position: relative;
    width: 100%;
    height: 143px;
    padding: 20px 30px 20px 20px;
    background-color: black;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  `}
`

export const SubMenuA = styled.a`
  display: block;
  ${breakpoint.lg`
    transform: translateY(0);
    box-shadow: 0 0 30px transparent;
    transition: transform 0.1s, box-shadow 0.3s;
    &:hover {
      transform: translateY(4px);
      box-shadow: 0 10px 30px rgba(0,0,0,0.15);
    }
  `}
`

export const SubMenuItemLabel = styled(H5)`
  @media (max-width: ${gridBreakpoints.lg - 1}px) {
    font-weight: 300;
    font-size: ${24 / fontBase}rem;
    letter-spacing: -${1 / 24}em;
    padding: 0.5em 0;
    color: ${colors.black};
  }
`

export const SubMenuItemContent = styled.div`
  width: 100%;
  position: relative;
  z-index: 1;
`
